import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { processEpisodes } from '../utils'
import FooterSection from './FooterSection'
import styles from './Layout.module.sass'
import MediaPlayer, { MediaContextProvider } from './MediaPlayer'
import { SiteContextProvider } from './SiteContext'
import SubscribeDialogController from './SubscribeDialog'

export default function Layout({ children }) {
  const { site, episodeNodes } = useStaticQuery(graphql`
    query {
      site: dataYaml {
        intro
        about
        quote {
          text
          citation
        }
        disclaimer
        copyright
        links {
          type
          name
          url
        }
      }
      episodeNodes: allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            id
            excerpt
            html
            frontmatter {
              season
              number
              title
              slug
              date
              url
              relUrl
              waveformRelUrl
              duration
              fileType
              fileSize
            }
          }
        }
      }
    }
  `)

  const episodes = processEpisodes(episodeNodes)

  return (
    <SiteContextProvider data={site}>
      <MediaContextProvider episodes={episodes}>
        <div className={styles.content}>{children}</div>
        <FooterSection className={styles.footer} />
        <MediaPlayer />
        <SubscribeDialogController />
      </MediaContextProvider>
    </SiteContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.any
}
