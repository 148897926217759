import React, { useRef, useContext, useEffect } from 'react'
import logo from '../../assets/ITCLogo.svg'
import styles from './MediaPlayer.module.sass'
import PlayPause from './PlayPause'
import Waveform from './Waveform'
import MediaContext from './MediaContext'
import IconButton from '../IconButton'
import { mdiSkipNext, mdiSkipPrevious, mdiShareVariant, mdiPodcast } from '@mdi/js'
import { showSubscribeDialog } from '../SubscribeDialog'
import { Link } from 'gatsby'

const windowGlobal = typeof window === 'undefined' ? {} : window

export default function MediaPlayer() {
  const {
    loading,
    playing,
    episode,
    episodes,
    setPlaying,
    setEpisode,
    setLoading
  } = useContext(MediaContext)
  const wf = useRef(null)

  useEffect(() => {
    if (!wf.current || !episode || !episode.url) return

    async function loadEpisode() {
      let peaksData
      if (episode.waveformRelUrl) {
        try {
          const res = await fetch(episode.waveformRelUrl)
          if (res.ok) {
            let peaks = await res.json()
            if (peaks && peaks.data) {
              peaksData = peaks.data
              console.log('Loaded waveforms')
            }
          }
        } catch (err) {
          console.warn('Unable to load peaks data', err)
        }
      }

      wf.current.load(episode.relUrl || episode.url, peaksData)
    }

    loadEpisode()
  }, [episode])

  useEffect(() => {
    if (!wf.current || loading) return
    if (wf.current.isPlaying() !== playing) {
      try {
        if (playing) wf.current.play()
        else wf.current.pause()
      } catch (e) {
        // ignore
      }
    }
  }, [loading, playing])

  const handlePlayPause = () => setPlaying(!playing)
  const handleLoading = () => {
    if (!loading) {
      setLoading(true)
      setPlaying(false)
    }
  }

  const epIndex = episodes.findIndex(ep => ep.id === episode.id)
  const nextEp = episodes[epIndex - 1] // reverse-chronoloigcal order
  const prevEp = episodes[epIndex + 1]

  const canShare = windowGlobal.navigator && navigator.share
  const handleShareSub = () => {
    if (canShare) {
      windowGlobal.navigator
        .share({
          title: episode.title,
          url: windowGlobal.location.origin + '/' + episode.slug
        })
        .catch(() => {}) // navigator.share throws an error when the user cancels
    } else {
      showSubscribeDialog()
    }
  }

  return (
    <div className={styles.root}>
      <Link className={styles.logoIcon} to='/'>
        <img src={logo} alt='ITC' />
      </Link>

      <PlayPause
        className={styles.playPause}
        loading={loading}
        playing={playing}
        onClick={handlePlayPause}
      />

      <div className={styles.info}>
        <span className={styles.title}>IS THIS CONFIRMATION</span>
        {episode && (
          <span className={styles.subject}>
            Epsiode {episode.number}: {episode.title}
          </span>
        )}
      </div>

      <Waveform
        ref={wf}
        onPlay={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        onLoading={handleLoading}
        onReady={() => setLoading(false)}
      />

      <div className={styles.actions}>
        <IconButton
          disabled={!nextEp}
          path={mdiSkipPrevious}
          onClick={() => setEpisode(nextEp)}
          clear
          white
        />
        <IconButton
          disabled={!prevEp}
          path={mdiSkipNext}
          onClick={() => setEpisode(prevEp)}
          clear
          white
        />
        <IconButton
          path={canShare ? mdiShareVariant : mdiPodcast}
          onClick={handleShareSub}
          clear
          white
        />
      </div>
      {/* PREV, NEXT, SHARE */}
    </div>
  )
}
