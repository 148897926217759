import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

const MediaContext = createContext()

export const MediaContextProvider = ({ episodes = [], children }) => {
  const [state, setState] = useState({
    episodes,
    loading: false,
    playing: false,
    episode: episodes[0],
    setLoading: loading => setState(prev => ({ ...prev, loading })),
    setPlaying: playing =>
      setState(prev => (prev.loading ? prev : { ...prev, playing })),
    setEpisode: episode => setState(prev => ({ ...prev, episode })),
    setEpisodes: episodes => setState(prev => ({ ...prev, episodes }))
  })

  return <MediaContext.Provider value={state}>{children}</MediaContext.Provider>
}
MediaContextProvider.propTypes = {
  episodes: PropTypes.array,
  children: PropTypes.any
}

export default MediaContext
