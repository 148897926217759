import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import Icon from '@mdi/react'
import styles from './IconButton.module.sass'

export default function IconButton({
  className,
  clear,
  component = 'div',
  disabled,
  onClick,
  path,
  size = 'default',
  spin,
  white,
  ...props
}) {
  const El = component

  return (
    <El
      className={clsx(
        styles.root,
        styles[size],
        clear && styles.clear,
        white && styles.white,
        disabled && styles.disabled,
        className
      )}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      {...props}
    >
      <Icon path={path} spin={spin} />
    </El>
  )
}

IconButton.propTypes = {
  className: PropTypes.string,
  clear: PropTypes.bool,
  component: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  path: PropTypes.any,
  size: PropTypes.string,
  spin: PropTypes.bool,
  white: PropTypes.bool
}
