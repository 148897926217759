import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

const SiteContext = createContext()

export const SiteContextProvider = ({ data, children }) => {
  const [state, setState] = useState({
    ...data,
    setData: data => setState(prev => ({ ...prev, ...data }))
  })

  return <SiteContext.Provider value={state}>{children}</SiteContext.Provider>
}
SiteContextProvider.propTypes = {
  data: PropTypes.object,
  children: PropTypes.any
}

export default SiteContext
