import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './Waveform.module.scss'

let WaveSurfer
let WaveSurferCursor
if (typeof window !== 'undefined') {
  WaveSurfer = require('wavesurfer.js')
  WaveSurferCursor = require('wavesurfer.js/dist/plugin/wavesurfer.cursor')
}

const Waveform = React.forwardRef(
  ({ onPlay, onPause, onLoading, onReady, onError }, ref) => {
    const [loading, setLoading] = useState(0)

    useEffect(() => {
      if (!WaveSurfer) return
      const wavesurfer = WaveSurfer.create({
        container: '#waveform',
        closeAudioContext: true,
        barWidth: 2,
        barHeight: 1, // the height of the wave
        barGap: null,
        height: 64,
        progressColor: '#ffffff', // '#D4496A',
        waveColor: '#ffffffc0',
        cursorColor: '#ffffff75',
        partialRender: true,
        plugins: [
          WaveSurferCursor.create({
            showTime: true,
            opacity: 1,
            customShowTimeStyle: {
              'background-color': '#000',
              color: '#fff',
              padding: '2px',
              'font-size': '10px'
            }
          })
        ],
        responsive: true
      })
      ref.current = wavesurfer
      wavesurfer.on('play', onPlay)
      wavesurfer.on('pause', onPause)
      wavesurfer.on('loading', percent => {
        setLoading(percent)
        onLoading(percent)
      })
      wavesurfer.on('ready', () => {
        setLoading(false)
        onReady()
      })
      wavesurfer.on('error', err => console.error(err))
      wavesurfer.on('seek', () => {
        wavesurfer.play()
      })

      return () => {
        wavesurfer.destroy()
      }
    }, [])

    return (
      <div id='waveform' className={styles.root}>
        {loading !== false && (
          <div
            className={styles.progress}
            style={{
              width: loading + '%'
            }}
          />
        )}
      </div>
    )
  }
)
Waveform.displayName = Waveform
Waveform.propTypes = {
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  onLoading: PropTypes.func,
  onReady: PropTypes.func,
  onError: PropTypes.func
}
export default Waveform
