import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import bgimg from '../assets/bgimg-full.jpg'
import Container from './Container'
import SiteContext from './SiteContext'
import styles from './FooterSection.module.sass'
import clsx from 'clsx'

export default function FooterSection({ className }) {
  const { disclaimer, copyright } = useContext(SiteContext)

  return (
    <footer
      className={clsx(styles.root, className)}
      style={{
        backgroundImage: `linear-gradient(to bottom, #ffffff00, #ffffffc0), url('${bgimg}')`
      }}
    >
      <Container>
        <p className={styles.disclaimer}>{disclaimer}</p>
        <p className={styles.copyright}>{copyright}</p>
      </Container>
    </footer>
  )
}

FooterSection.propTypes = {
  className: PropTypes.string
}
