import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef, useContext } from 'react'
import styles from './SubscribeDialog.module.sass'
import Icon from '@mdi/react'
import {
  mdiSpotify,
  mdiGooglePodcast,
  mdiApple,
  mdiSoundcloud,
  mdiRss,
  mdiClose
} from '@mdi/js'
import iconOvercast from '../assets/icon-overcast.svg'
import iconPocketCasts from '../assets/icon-pocketcasts.svg'
import iconRadioPublic from '../assets/icon-radiopublic.svg'
import iconStitcher from '../assets/icon-stitcher.svg'
import IconButton from './IconButton'
import SiteContext from './SiteContext'

const ICONS = {
  spotify: mdiSpotify,
  google: mdiGooglePodcast,
  apple: mdiApple,
  soundcloud: mdiSoundcloud,
  rss: mdiRss
}
const EXTRAS = {
  overcast: iconOvercast,
  pocketCasts: iconPocketCasts,
  radioPublic: iconRadioPublic,
  stitcher: iconStitcher
}

function SubscribeDialog({ links = [], onClose }) {
  const ref = useRef(null)
  const handleClick = e => {
    if (!ref.current || !ref.current.contains(e.target)) return onClose()
  }

  return (
    <div className={styles.root} onClick={handleClick}>
      <div className={styles.content} ref={ref}>
        <h4>SUBSCRIBE</h4>
        <IconButton
          className={styles.close}
          path={mdiClose}
          clear
          onClick={onClose}
        />
        <div className={styles.links}>
          {links.map((link, i) => (
            <a
              key={i}
              className={styles.linkItem}
              href={link.url}
              target='_blank'
              rel='noreferrer'
            >
              {ICONS[link.type] && <Icon size='32px' path={ICONS[link.type]} />}
              {EXTRAS[link.type] && <img src={EXTRAS[link.type]} />}
              {link.name}
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}
SubscribeDialog.propTypes = {
  links: PropTypes.array,
  onClose: PropTypes.func
}

let singletonRef = {}

export default function SubscribeDialogController() {
  const { links } = useContext(SiteContext)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const handler = () => setOpen(true)
    singletonRef.open = handler
    return () => {
      if (singletonRef.open == handler) {
        singletonRef.open = null
      }
    }
  }, [])

  if (!open) return null
  return <SubscribeDialog links={links} onClose={() => setOpen(false)} />
}

export function showSubscribeDialog() {
  if (singletonRef.open) {
    singletonRef.open()
  }
}
