exports.processEpisodes = episodeNodes => {
  const results = []

  const edges = episodeNodes.edges
  for (let i = 0; i < edges.length; i++) {
    if (!edges[i] || !edges[i].node) continue
    const node = edges[i].node
    results.push({
      ...node.frontmatter,
      id: node.id,
      excerpt: node.excerpt,
      html: node.html
    })
  }

  return results
}

exports.makeItunesCategories = categories => {
  return categories.map(cat => ({
    'itunes:category': { _attr: { text: cat } }
  }))
}
