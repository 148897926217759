import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@mdi/react'
import { mdiPlay, mdiPause, mdiLoading } from '@mdi/js'

export default function PlayPause({ loading, playing, className, onClick }) {
  return (
    <Icon
      className={className}
      onClick={onClick}
      path={loading ? mdiLoading : playing ? mdiPause : mdiPlay}
      spin={loading}
      title={mdiPlay ? 'Pause' : 'Play'}
      color='white'
      size='40px'
    />
  )
}

PlayPause.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  playing: PropTypes.bool
}
